button,
button:focus,
button:target {
  appearance: none;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

.btn {
  padding: 0 #{$default-spacing * 2.5};
  border: map-get($styling, default-border) map-get($colors, black);
  border-radius: 42px;
  display: inline-block;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-out;
  color: inherit;
  border-color: map-get($colors, black);
  overflow: hidden;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: map-get($colors, black);
    transition: 0.3s all ease-in-out;
    z-index: -1;
  }

  &:hover {
    color: map-get($colors, white);

    &::after {
      width: 100%;
    }
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    display: block;
    text-align: center;
  }

  p {
    @include fluid-type(650px, 1200px, 16px, 32px);
    line-height: 68px;
    margin: 0;
    z-index: 2;

    @include breakpoint-max(map-get($breakpoints, small)) {
      @include fluid-type(320px, 650px, 24px, 32px);
      line-height: 48px;
    }
  }

  &:target,
  &:focus,
  &:hover {
    background: map-get($colors, black);
    color: map-get($colors, white);
  }
}

%button-bright {
  @extend .btn;

  border-color: map-get($colors, white);

  &::after {
    background: map-get($colors, white);
  }

  &:hover {
    color: map-get($colors, black);

    &::after {
      width: 100%;
    }
  }
}
