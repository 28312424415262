html {
  font-family: map-get($fonts, default-sans-serif);
  font-weight: 700;
  color: map-get($colors, black);
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-size: 80px;
  font-family: map-get($fonts, default-serif);
  line-height: 1;
  margin-bottom: 80px;
  text-transform: uppercase;
  text-align: center;
}

h2 {
  // font-size: 32px;
  font-family: map-get($fonts, default-serif);
  line-height: 1.25;
  margin-bottom: 40px;

  @include fluid-type(700px, 1200px, 18px, 32px);

  @include breakpoint-max(map-get($breakpoints, small)) {
    @include fluid-type(320px, 650px, 24px, 32px);
  }
}

h3 {
  font-size: 28px;
  line-height: 1.1;
  font-family: map-get($fonts, default-sans-serif);
}

h4 {
  font-size: 24px;
  font-family: map-get($fonts, default-sans-serif);
  line-height: 1.33;
  margin-bottom: 30px;
}

h5 {
  font-size: 20px;
  font-family: map-get($fonts, default-sans-serif);
  color: map-get($colors, accent1);
  line-height: 1.33;
  margin-bottom: 30px;
}

blockquote {
  font-size: 80px;
  font-family: map-get($fonts, default-serif);
  color: map-get($colors, orange);
  line-height: 1.33;
  margin-bottom: 80px;
  text-align: right;
  max-width: 500px;
  float: right;

  &::before {
    content: '“';
  }

  &::after {
    content: '”';
  }
}

p {
  font-size: 24px;
  font-family: map-get($fonts, default-sans-serif);
  line-height: 1.33;
  font-weight: 700;
  margin-bottom: 24px;

  // @include breakpoint-max(map-get($breakpoints, small)) {
  //   font-size: 12px;
  //   line-height: 1.33;
  // }

  a {
    color: inherit;
    border-bottom: map-get($styling, default-border) map-get($colors, accent1);
  }
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

ol,
ul {
  font-size: 24px;
  font-family: map-get($fonts, default-erif);
  line-height: 1.33;
  font-weight: 700;
  margin-bottom: 24px;
  text-indent: -40px;
  list-style-position: inside;
  padding-left: 40px;
}

ol {
  list-style-type: decimal-leading-zero;
}

ul {
  list-style-type: square;
}
