footer {
  padding: #{$default-spacing * 6} map-get($default-side-padding, desktop);
  min-height: 50vh;
  display: flex;
  flex-wrap: wrap;;

  .org--footer-links__anchor p {
    font-size: 16px;
    display: inline-block;

    @include breakpoint-max(map-get($breakpoints, small)) {
      font-size: 12px;
    }
  }

  .org--address {
    width: 100%;
  }

  .footer__bottom-links {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    justify-self: flex-end;
    width: 100%;
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: #{$default-spacing * 6} map-get($default-side-padding, small);
  }
}
