.itm--teaser-image {
  .teaser-image--padding {
    position: absolute;
    top: 200px;
    bottom: 150px;
    left: 15%;
    right: 15%;
    height: auto;
  }
}
