/* autoprefixer grid: on */
/* autoprefixer grid: autoplace */

/* Reset */
@import 'reset-css';
@import '../../assets/scss/default';

// /* Line Awesome */
// // @import '~line-awesome/dist/css/line-awesome.min.css';

// /* Generic */
@import '../../assets/scss/generic/theme/luf/font-faces.scss';
@import '../../assets/scss/generic/theme/luf/variables';
@import '../../assets/scss/generic/mixins';
@import '../../assets/scss/generic/theme/luf/mixins';
@import '../../assets/scss/generic/theme/luf/buttons';
@import '../../assets/scss/generic/theme/luf/headings';

// /* Default Styling ----------- */

// /* Modules */
@import '../../assets/scss/generic/modules';

// /* Modules Default Theme */
@import '../../assets/scss/generic/theme/luf/modules';

/* Edit Mode CSS */
.k-panel.kBuilderPreview__frame {
  padding: 16px;
  border: 4px dotted black;
  background: yellow !important;

}
