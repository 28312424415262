.mod--teaser-image-group {
  position: relative;

  @for $i from 1 through 12 {
    @include height($i);
  }

  .wrapper {
    width: 100%;
    height: 100%;
  }

  .itm--teaser-text {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 60px 8%;
    display: grid;
    align-items: start;
    grid-template-rows: [line1] auto [line2] 1fr [line3];
    grid-template-areas:
      'header'
      'content';

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    @include breakpoint-max(map-get($breakpoints, medium)) {
      padding: 30px 12%;
    }

    p {
      margin-bottom: 0;
    }

    &__content {
      align-self: center;
      position: relative;
      bottom: auto;
      left: auto;
      padding-right: 0;
      grid-area: content;

      @include breakpoint-max(map-get($breakpoints, medium)) {
        h2 {
          font-size: 18px;
        }

        h1 {
          font-size: 30px;
        }
      }
    }

    @include breakpoint-max(map-get($breakpoints, medium)) {
      &[class*="grid-span"] {
        height: 50vh;
        grid-column-end: span 12;
        grid-column-start: 1;
      }
    }
  }

  .itm--teaser-image {
    @include breakpoint-max(map-get($breakpoints, medium)) {
      &[class*="grid-span"] {
        height: 50vh;
        grid-column-end: span 12;
        grid-column-start: 1;

        .itm--teaser-image__media {
          min-height: 50vh;
        }
      }
    }
  }

  .glide__slides {
    height: 100%;
    margin-bottom: 0;
  }

  .slider--glide {
    height: auto;
  }

  .glide__arrows {
    .glide__arrow {
      position: absolute;
    }

    .glide__arrow--left {
      left: 20px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        left: 10px;
      }
    }

    .glide__arrow--right {
      right: 20px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        right: 10px;
      }
    }

    svg {
      width: 26px;
      height: 40px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        width: 16px;
        height: 25px;
      }
    }
  }
}
