@import "node_modules/tiny-slider/src/tiny-slider";

.org--default-slider,
.slider--glide {
  height: 100%;
  width: 100%;
  // grid-column-end: span 12;
  position: relative;

  .tns-outer,
  .tns-inner {
    max-height: 100%;
  }
}
