.mod--instagram-feed {
  padding: 0 map-get($default-side-padding, desktop) 80px;

  @for $i from 1 through 12 {
    @include height($i);
  }

  &__target {
    display: grid;

    .instagram_gallery {
      grid-column-end: span 12;
      width: calc(100% + #{$default-spacing});
      margin-left: -$default-spacing / 2;

      @extend .grid;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex-wrap: wrap;
      }

      a {
        grid-column-end: span 4;
        padding: $default-spacing / 2;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          @include flex-basis-fix(33.3333%);
        }

        img {
          height: 100%;
          width: 100%;
        }

        @include breakpoint-max(map-get($breakpoints, small)) {
          grid-column-end: span 6;
          padding: $default-spacing;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            @include flex-basis-fix(50%);
          }
        }
      }

      @include breakpoint-max(map-get($breakpoints, small)) {
        width: calc(100% + #{$default-spacing * 2});
        margin-left: -$default-spacing;
      }
    }
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: 0 map-get($default-side-padding, small) 80px;
  }

  // .itm--teaser-text {
  //   width: 50%;
  //   float: left;
  // }

  // .itm--teaser-image {
  //   width: 50%;
  //   float: left;
  // }
}
