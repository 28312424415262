.mod--distribution {
  @include breakpoint-max(map-get($breakpoints, small)) {
    height: auto !important;
  }

  @for $i from 1 through 12 {
    @include height($i);
  }

  &__title-image {
    // height: 100%;
    overflow: hidden;
    height: #{calc((100vh / 12 * 12))};

    @include breakpoint-max(map-get($breakpoints, small)) {
      grid-column-start: 1;
      order: 2;
      grid-column-end: span 12;
      height: 50vh;
    }
  }

  &__content {
    padding: 100px 60px;

    @include breakpoint-max(map-get($breakpoints, small)) {
      grid-column-start: 1;
      order: 1;
      grid-column-end: span 12;
      padding: 60px map-get($default-side-padding, small);
    }

    &__description {
      h2 {
        font-size: 32px;
        margin-bottom: 32px;

        @include breakpoint-max(map-get($breakpoints, small)) {
          font-size: 21px;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.22;
        margin-bottom: 40px;

        @include breakpoint-max(map-get($breakpoints, small)) {
          font-size: 12px;
          max-width: 80%;
        }
      }
    }

    &__options {
      &__wrapper {
        position: relative;
      }

      .edd-value {
        border: none;
        appearance: none;
        outline: none;
        border-bottom: 5px solid map-get($colors, black);
        border-radius: 0;
        font-size: 18px;
        line-height: 1.22;
        padding-bottom: 15px;
        background: none;
        display: block;
        max-width: 100%;
        font-family: map-get($fonts, default-sans-serif);
        width: 100%;

        &:target,
        &:focus,
        &:hover {
          color: map-get($colors, yellow);
          cursor: pointer;
        }

        @include breakpoint-max(map-get($breakpoints, small)) {
          font-size: 12px;
          border-bottom-width: 3px;
          padding-bottom: 10px;
        }
      }

      &__entry {
        padding-top: 50px;

        h3 {
          font-size: 18px;
          line-height: 1.22;
          margin-bottom: 10px;
        }

        p {
          font-size: 18px;
          line-height: 1.22;
          margin-bottom: 20px;
        }
      }

      .edd-root {
        &::after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 40'%3E%3Cpath d='M0 5.705v.828L13.467 20 0 33.467v.828L5.705 40h.828L20 26.533l6-6v-1.066l-6-6L6.533 0h-.828z'/%3E%3C/svg%3E");
          width: 12px;
          height: 12px;
          position: absolute;
          right: 0;
          top: 13px;
          transition: all 0.3s ease-out;
          transform: translateY(-50%) rotate(180deg);

          @include breakpoint-max(map-get($breakpoints, small)) {
            width: 9px;
            height: 9px;
          }
        }
      }

      .edd-body {
        display: none;
        padding-top: 20px;
        font-size: 18px;
        line-height: 1.67;
      }

      .edd-group {
        background: map-get($colors, white);
        color: map-get($colors, black);
        padding-bottom: 20px;

        .edd-option {
          margin-bottom: 8px;
          cursor: pointer;

          &:hover {
            color: map-get($colors, yellow);
          }
        }
      }

      .edd-root-open {
        .edd-body {
          display: block;
        }

        &::after {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }
}
