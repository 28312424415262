.default-video {
  height: 100%;

  .default-video__media-wrapper {
    height: 100%;

    &.object-fit--cover {
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}
