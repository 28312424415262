.art--container {
  &__headline__holder {
    padding: 130px map-get($default-side-padding, desktop) 50px;

    @include breakpoint-max(map-get($breakpoints, small)) {
      padding: 32px map-get($default-side-padding, small) 60px;

      h3 {
        font-size: 21px;
      }
    }

    h3 + p {
      margin-top: $default-spacing * 5;
    }
  }

  &.background-color--orange {
    color: map-get($colors, white);
  }
}
