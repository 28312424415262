.mol--gallery-gate {
  height: 100%;

  .glide__slides {
    height: 100%;

    ul {
      text-indent: 0;
    }

    .art--default-slide {
      height: 100%;

      &__media {
        height: 100%;
      }
    }
  }

  figure {
    height: 100%;
    overflow: hidden;
  }
}
