.mod--teaser-animation {
  .itm--teaser-text {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 30px;

    .itm--teaser-text__vector {
      animation-name: rotate;
      animation-duration: 18s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      svg {
        align-self: center;
        justify-self: center;
        width: 100%;
        height: 100%;
      }
    }

    @keyframes rotate {
      from {transform: rotate(0deg)}
      to {transform: rotate(360deg)}
    }
  }
}
