$theme-name: 'default';

.glide__arrows {
  position: absolute;
  top: calc((100% - 80px) / 2);
  width: 100%;
  transform: translateY(-50%);

  .glide__arrow {
    padding: $default-spacing * 2;
    background: map-get($colors, accent1);
    color: map-get($colors, black);
    font-size: 2rem;
    transition: map-get($animation, default-transition);

    &--left {
      position: absolute;
      left: 0;
    }

    &--right {
      position: absolute;
      right: 0;
    }

    &:hover,
    &:target,
    &:focus {
      background: map-get($colors, black);
      color: map-get($colors, white);
    }
  }
}

.glide__controls {
  padding: $default-spacing * 5 0 0;
  text-align: center;

  button.glide__bullet {
    border: map-get($styling, default-border) map-get($colors, black);
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 5px;
    margin: 0 10px;
    transition: map-get($animation, default-transition);

    &.glide__bullet--active,
    &:hover,
    &:target,
    &:focus {
      background: map-get($colors, accent1);
      border: map-get($styling, default-border) map-get($colors, accent1);
    }
  }
}

