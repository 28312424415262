@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .grid {
    display: flex;
  }

  .grid-span--,
  .grid-column-span-- {
    &1 {
      @include flex-basis-fix(calc((100% / 12) * 1));
    }

    &2 {
      @include flex-basis-fix(calc((100% / 12) * 2));
    }

    &3 {
      @include flex-basis-fix(calc((100% / 12) * 3));
    }

    &4 {
      @include flex-basis-fix(calc((100% / 12) * 4));
    }

    &5 {
      @include flex-basis-fix(calc((100% / 12) * 5));
    }

    &6 {
      @include flex-basis-fix(calc((100% / 12) * 6));
    }

    &7 {
      @include flex-basis-fix(calc((100% / 12) * 7));
    }

    &8 {
      @include flex-basis-fix(calc((100% / 12) * 8));
    }

    &9 {
      @include flex-basis-fix(calc((100% / 12) * 9));
    }

    &10 {
      @include flex-basis-fix(calc((100% / 12) * 10));
    }

    &11 {
      @include flex-basis-fix(calc((100% / 12) * 11));
    }

    &12 {
      @include flex-basis-fix(calc((100% / 12) * 12));
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: [line1] 1fr [line2] 1fr [line3] 1fr [line4] 1fr [line5] 1fr [line6] 1fr [line7] 1fr [line8] 1fr [line9] 1fr [line10] 1fr [line11] 1fr [line12] 1fr [line13];
  grid-gap: map-get($grid, gap);

  &--centered {
    justify-content: center;
  }

  &-cluster {
    grid-template-rows: repeat(auto-fill, 10vh);
  }
}

.grid-span--,
.grid-column-span-- {
  &1 {
    grid-column-end: span 1;
  }

  &2 {
    grid-column-end: span 2;
  }

  &3 {
    grid-column-end: span 3;
  }

  &4 {
    grid-column-end: span 4;
  }

  &5 {
    grid-column-end: span 5;
  }

  &6 {
    grid-column-end: span 6;
  }

  &7 {
    grid-column-end: span 7;
  }

  &8 {
    grid-column-end: span 8;
  }

  &9 {
    grid-column-end: span 9;
  }

  &10 {
    grid-column-end: span 10;
  }

  &11 {
    grid-column-end: span 11;
  }

  &12 {
    grid-column-end: span 12;
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    &1,
    &2,
    &3,
    &4,
    &5,
    &6,
    &7,
    &8,
    &9,
    &10,
    &11,
    &12 {
      grid-column-end: span 12;
    }
  }
}

.grid-row-span-- {
  &1 {
    grid-row-end: span 1;
    height: 10vh;
  }

  &2 {
    grid-row-end: span 2;
    height: 20vh;
  }

  &3 {
    grid-row-end: span 3;
    height: 30vh;
  }

  &4 {
    grid-row-end: span 4;
    height: 40vh;
  }

  &5 {
    grid-row-end: span 5;
    height: 50vh;
  }

  &6 {
    grid-row-end: span 6;
    height: 60vh;
  }

  &7 {
    grid-row-end: span 7;
    height: 70vh;
  }

  &8 {
    grid-row-end: span 8;
    height: 80vh;
  }

  &9 {
    grid-row-end: span 9;
    height: 90vh;
  }

  &10 {
    grid-row-end: span 10;
    height: 100vh;
  }
}

.grid-start-- {
  &1 {
    grid-column-start: 1;
  }

  &2 {
    grid-column-start: 2;
  }

  &3 {
    grid-column-start: 3;
  }

  &4 {
    grid-column-start: 4;
  }

  &5 {
    grid-column-start: 5;
  }

  &6 {
    grid-column-start: 6;
  }

  &7 {
    grid-column-start: 7;
  }

  &8 {
    grid-column-start: 8;
  }

  &9 {
    grid-column-start: 9;
  }

  &10 {
    grid-column-start: 10;
  }

  &11 {
    grid-column-start: 11;
  }

  &12 {
    grid-column-start: 12;
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    &1,
    &2,
    &3,
    &4,
    &5,
    &6,
    &7,
    &8,
    &9,
    &10,
    &11,
    &12 {
      grid-column-start: 1;
    }
  }
}

@include breakpoint-max(map-get($breakpoints, small)) {
  .grid-order-- {
    &1 {
      order: 1;
    }

    &2 {
      order: 2;
    }

    &3 {
      order: 3;
    }
  }
}
