/* stylelint-disable value-list-comma-newline-after */

/* ----- GT Super ----- */

/* Light */
@font-face {
  font-family: 'TildeBold';
  src:
    url('/assets/fonts/themes/luf/tilde/bold/tilde-bold.otf') format('opentype'),
    url('/assets/fonts/themes/luf/tilde/bold/tilde-bold.otf') format('truetype'),
    url('/assets/fonts/themes/luf/tilde/bold/tilde-bold.woff') format('woff'),
    url('/assets/fonts/themes/luf/tilde/bold/tilde-bold.woff2') format('woff2'),;
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
