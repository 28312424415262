.mol--social-link {
  // max-width: 180px;
  // padding: 0 $default-spacing * 3;
  // text-align: center;
  cursor: pointer;
  list-style-type: none;
  text-indent: 0;
  margin-right: $default-spacing * 2;

  &__icon {
    width: 40px;
    height: 40px;
    // border-radius: 40px;
    // border: 2px solid map-get($colors, white);
    position: relative;
    // margin-bottom: $default-spacing * 4;

    svg {
      max-height: 40px;
      max-width: 40px;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
  }

  p.mol--social-link__anchor-text {
    font-size: 16px;
    display: inline;
  }

  &:hover {
    p.mol--social-link__anchor-text {
      border-bottom: 2px solid map-get($colors, white);
    }
  }
}
