.org--footer-links {
  width: auto;

  &__holder {
    text-indent: 0;
    padding-left: 0;
    list-style-type: none;
    display: flex;
    align-self: center;
    height: 100%;
    width: auto;

    li {
      align-self: center;

      &:last-of-type {
        a {
          margin-right: 0;
        }
      }
    }

    .org--footer-links__anchor {
      font-size: 16px;
      margin-right: $default-spacing * 2.5;

      p {
        margin-bottom: 0;
      }

      &:hover {
        border-bottom: 2px solid map-get($colors, white);
      }
    }
  }
}
