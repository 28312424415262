.mod--stage {
  position: relative;

  /* Height Variables */
  @for $i from 1 through 12 {
    @include height($i);
  }

  &__media {
    height: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;

    &__holder {
      height: 30%;
      width: 25vw;
      justify-self: center;
      align-self: center;
      text-align: center;

      @include breakpoint-max(map-get($breakpoints, small)) {
        width: 100%;
      }

      svg {
        fill: map-get($colors, white);
        width: auto;
        height: 100%;
      }
    }

    &__description {
      bottom: 25vh;
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      color: map-get($colors, white);
    }
  }
}
