.cookie-banner.theme--alma {
  padding: 70px map-get($default-side-padding, desktop);
  background: map-get($colors, orange);

  .cookie-banner__content {
    &__description {
      max-width: 960px;
      color: map-get($colors, white);

      h2 {
        font-family: map-get($fonts, default-sans-serif);
        font-size: 24px;
        line-height: 1.33;
        margin-bottom: 20px;
      }

      p {
        font-weight: 300;
        font-size: 22px;
        line-height: 1.27;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &__cta {
      padding-top: 50px;
      max-width: 960px;

      .btn {
        line-height: 50px;
        font-size: 24px;
        font-weight: 700;
        min-width: 260px;
        margin: 0 20px 20px 0;
        text-align: center;
        color: map-get($colors, white);
        border-color: map-get($colors, white);
      }
    }

    &__further-reading {
      text-align: right;
      color: map-get($colors, white);

      a p {
        font-size: 16px;
      }
    }
  }
}
