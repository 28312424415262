/* Spacing */
$default-spacing: 8px;
$default-margin-bottom: #{$default-spacing * 5};
$default-side-padding: (
  small: 30px,
  medium: 30px,
  desktop: 30px,
);

/* Content */
$max-content-width: 1280px;

/* Fonts Variables */
$fonts: (
  default-sans-serif: #{'TildeBold', Helvetica, Arial, sans-serif},
);

/* Grid Variables */
$grid: (
  gap: $default-spacing * 0,
  gap-cluster: $default-spacing * 2,
);

/* Colors */
$colors: (
  white: rgb(255, 255, 255),
  black: rgb(0, 0, 0),
  grey-medium: rgb(150, 150, 150),
  grey-light: rgb(200, 200, 200),
  grey-lightest: rgb(246, 246, 246),
);

/* Styling Variables */
$styling: (
  default-shadow: 0 0 5px 0 map-get($colors, grey-light),
  default-border: 3px solid,
  default-border-radius: 4px,
);

/* Animation Variables */
$animation: (
  default-transition: 0.3s all ease-out,
);

$height-step: calc(100vh / 12);

$dark-colors: (
  black: map-get($colors, black),
  orange: map-get($colors, orange),
);

$breakpoints: (
  small: 650px,
  medium: 1200px,
  // desktop: 1200,
);
