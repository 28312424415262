$theme-name: 'default';

&.theme--#{$theme-name} {
  figure {
    // margin: 4rem 0;

    figcaption {
      font-size: 1.6rem;
      font-family: map-get($fonts, default-sans-serif);
      color: map-get($colors, grey-medium);
      line-height: 1.6;
      margin-top: 1.5rem;
    }
  }

  a {
    transition: map-get($animation, default-transition);

    &:hover,
    &:target {
      color: map-get($colors, accent1);

      i {
        color: map-get($colors, accent1);
      }
    }

    i {
      transition: map-get($animation, default-transition);
      color: map-get($colors, black);
    }
  }
}
