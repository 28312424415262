figure {
  background: map-get($colors, white);

  img {
    width: 100%;
    height: 100%;
  }

  div.default-figure__image-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;

    img {
      object-fit: contain;
      object-position: center center;
      font-family: 'object-fit: contain; object-position: center center;';
      // display: block;
    }

    &.object-fit--cover {
      img {
        object-fit: cover;
        object-position: center center;
        height: 100%;
        font-family: 'object-fit: cover; object-position: center center;';
      }
    }
  }
}
