$theme-name: 'luf';

.mod--rich-text.theme--#{$theme-name} {
  padding: 20vh map-get($default-side-padding, desktop) 20vh;
  position: relative;

  .mod--rich-text__content {
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2;

    &.orientation {
      &--left {
        text-align: left;
      }

      &--centered {
        text-align: center;
      }

      &--right {
        text-align: right;
      }
    }

    figure {
      figcaption {
        font-size: 1.6rem;
        font-family: map-get($fonts, default-sans-serif);
        color: map-get($colors, accent1);
        line-height: 1.6;
        margin-top: 1.5rem;
        margin-bottom: 40px;
      }
    }

    a {
      transition: map-get($animation, default-transition);

      &:hover,
      &:target {
        color: map-get($colors, accent1);

        i {
          color: map-get($colors, accent1);
        }
      }

      i {
        transition: map-get($animation, default-transition);
        color: map-get($colors, black);
      }
    }
  }

  .mod--rich-text__cross {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.sticky {
      position: fixed;
    }
  }
}
