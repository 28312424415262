.org--main-menu {
  &__entry {
    font-size: 32px;
    line-height: 1.1;
    margin-bottom: $default-spacing * 2;
    text-align: center;
    font-family: map-get($fonts, default-serif);
    list-style-type: none;
    text-align: center;

    &:hover,
    &.active {
      text-decoration: underline;
    }
  }
}
