.mod--further-action {
  padding: map-get($default-side-padding, desktop) map-get($default-side-padding, desktop) 80px;
  text-align: left;

  &__content {
    max-width: 50%;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    min-height: 100%;

    @include breakpoint-max(map-get($breakpoints, small)) {
      max-width: 100%;
    }

    p {
      width: 100%;
    }

    // p {
    //   font-size: 32px;
    //   margin-bottom: 60px;
    // }

    // a p {
    //   margin-bottom: 0;
    //   font-size: 20px;
    // }
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: 50px map-get($default-side-padding, small);
  }
}
