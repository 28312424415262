.main-navigation {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;

  a:hover,
  a:target,
  a:visited,
  .org--main-menu__entry.active a {
    color: inherit !important;
  }
}
