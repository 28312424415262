.itm--teaser-text {
  text-align: left;
  padding: 30px 30px 80px;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: 30px 30px 40px;
  }

  /* Height Variables */
  @for $i from 1 through 12 {
    @include quadraticHeight($i);
  }

  &__content {
    width: 100%;
    align-self: flex-start;
    position: relative;
  }

  &__cta {
    align-self: flex-end;

    @include breakpoint-max(map-get($breakpoints, small)) {
      width: 100%;
    }
  }

  &__subline {
    align-self: flex-end;

    p {
      font-size: 16px;
      line-height: 1.13;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  // &__content {
  //   h1 {
  //     @include fluid-type(700px, 1200px, 36px, 76px);
  //   }

  //   h2 {
  //     @include fluid-type(700px, 1200px, 12px, 36px);

  //     margin-bottom: 24px;
  //   }

  //   @include breakpoint-max(map-get($breakpoints, small)) {
  //     h2 {
  //       font-size: 12px;
  //       margin-bottom: 24px;
  //     }

  //     h1 {
  //       font-size: 26px;
  //       line-height: 1.1;
  //       margin-bottom: 24px;
  //     }
  //   }
  // }

  &.text-align--left {
    text-align: left;
  }
}
