.org--address {
  &__logo {
    margin-bottom: $default-spacing * 4;
    width: 30px;

    svg {
      max-width: 100%;
    }

    // @include breakpoint-max(map-get($breakpoints, small)) {
    //   max-width: 150px;
    // }
  }

  address {
    p {
      font-size: 18px;
      margin-bottom: $default-spacing * 2;

      // @include breakpoint-max(map-get($breakpoints, small)) {
      //   font-size: 12px;
      // }
    }
  }
}
