.mod--teaser-slideshow {
  position: relative;
  padding: 30px;

  /* Height Variables */
  @for $i from 1 through 12 {
    @include quadraticHeight($i);
  }

  .wrapper {
    height: 100%;

    .itm--teaser-image__media,
    .art--default-slide__media {
      height: 100% !important;

      @include breakpoint-max(map-get($breakpoints, small)) {
        min-height: 100% !important;
      }
    }

    .itm--teaser-text__subline {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 50px 0;

      @include breakpoint-max(map-get($breakpoints, small)) {
        padding: 0;
      }
    }

    .tns-outer,
    .tns-inner {
      height: 100% !important;

      .tns-item {
        height: 100% !important;
      }
    }
  }
}
