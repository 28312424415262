.main-navigation {
  &__holder {
    z-index: 9999;
    position: relative;
    padding: 60px map-get($default-side-padding, desktop);
    display: grid;
    grid-template-areas: "logo . . toggle";

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      justify-content: space-between;
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      padding: 30px map-get($default-side-padding, small);
    }

    .main-navigation.sticky & {
      background: map-get($colors, yellow);
      padding: 20px map-get($default-side-padding, desktop);
    }
  }

  &__logo {
    width: auto;
    grid-area: logo;
    font-size: 20px;
    line-height: 20px;
    text-align: left;

    svg {
      height: 20px;
      transition: 0.3s all ease-out;
      fill: map-get($colors, black);
      width: auto;
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      width: auto;
    }
  }

  &__back-link {
    grid-area: toggle;
    font-size: 20px;
    justify-self: end;
  }

  &__toggle {
    grid-area: toggle;
    width: 30px;
    height: 20px;
    justify-self: end;
    align-self: end;
    cursor: pointer;

    &__content {
      position: relative;
      height: 100%;
    }

    &__line {
      width: 30px;
      height: 4px;
      background: map-get($colors, black);
      position: absolute;
      transition: 0.3s all ease-out;

      &:nth-of-type(1) {
        top: 0;
        left: 0;
        // background: blue;
      }

      &:nth-of-type(2) {
        top: 8px;
        left: 0;
        // background: cyan;
      }

      &:nth-of-type(3) {
        bottom: 0;
        left: 0;
        // background: yellow;
      }
    }

    &.active {
      .main-navigation__toggle__line {
        &:nth-of-type(1) {
          transform: translateY(7px) rotate(45deg);
          // transform-origin: bottom left;
        }

        &:nth-of-type(2) {
          display: none;
        }

        &:nth-of-type(3) {
          transform: translateY(-10px) rotate(-45deg);
          // transform-origin: top left;
        }
      }
    }
  }

  &__overlay {
    z-index: 9998;
    position: fixed;
    transform: translateX(100%);
    top: 0;
    right: 0;
    height: 100%;
    width: 50vw;
    transition: 0.5s all ease-in;
    display: grid;
    grid-template-areas:
      "header header header header"
      "menu menu menu menu"
      "footer footer footer footer";

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      align-content: space-between;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 130px;
    }

    @include breakpoint-max(map-get($breakpoints, medium)) {
      width: 100vw;
    }

    &.active {
      transform: translateX(0);
    }

    .org--footer-links {
      grid-area: footer;
      bottom: 100px;
      text-align: center;
      width: 100%;
      align-self: end;
      padding-bottom: 60px;

      &__holder {
        display: block;
        text-align: center;

        li {
          display: inline-block;

          a {
            margin-right: 0;
          }
        }
      }

      &__anchor {
        p {
          font-size: 12px;
        }
      }
    }

    .org--main-menu {
      grid-area: menu;
      justify-self: center;
      align-self: center;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        align-self: center;
      }
    }
  }

  // &.overlay-active {
  //   .main-navigation__logo {
  //     svg {
  //       fill: map-get($colors, white);
  //     }
  //   }

  //   .main-navigation__toggle__line {
  //     background: map-get($colors, white);
  //   }
  // }
}
