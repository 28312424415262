.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s transform ease-out;
  transform: translateY(100%);

  &.shown {
    transform: translateY(0);
  }
}
