$theme-name: 'default';

&.theme--#{$theme-name} {
  position: relative;

  .slider--glide {
    width: 100%;
  }

  @import 'controls';
}
