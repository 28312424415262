.mod--teaser-image {
  position: relative;

  /* Height Variables */
  @for $i from 1 through 12 {
    @include quadraticHeight($i);
  }

  .wrapper {
    height: 100%;

    .mod--teaser-image__cta {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 80px 32px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        padding: 60px 30px;
      }
    }
  }
}
